<template>
  <div class="tabbar">
    <div class="left__bg"></div>
    <div class="main">
      <ul class="left__tabbar__ul">
        <li
          :class="{ current: item.value == current }"
          v-for="(item, index) in tabbar"
          :key="index"
          @click="onItemClick(item)"
        >
          <span v-html="item.key"></span>
        </li>
      </ul>
      <div class="icon">
        <img :src="require('images/common/icon1.png')" />
      </div>
      <div class="right__content" @click="ask('/web/ask')">
        <img :src="require('images/common/icon2.png')" />
        <span class="txt">我要问政</span>
      </div>
    </div>
    <div class="right__bg"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getLocalStorage } from "@/utils/tools.js";
import { getUserDeatil } from "@/api/outh";
export default {
  name: "Tabbar",
  computed: {
    ...mapState({
      current: (state) => state.currentTab,
    }),
  },
  data() {
    return {
      // current: 1,
      tabbar: [
        {
          key: "首&nbsp;&nbsp;页",
          value: 1,
          path: "/web/index",
        },
        {
          key: "权威发布",
          value: 2,
          path: "/web/release",
        },
        {
          key: "媒体监督",
          value: 3,
          path: "/web/media",
        },
        {
          key: "在线问政",
          value: 4,
          path: "/web/online/1/1",
        },
        // {
        //   key: "民间智库",
        //   value: 5,
        //   path: "/web/suggest",
        // },
        // {
        //   key: "政策问答",
        //   value: 6,
        //   path: "/web/policy",
        // },
        {
          key: "排行榜",
          value: 7,
          path: "/web/rank",
        },
      ],
    };
  },
  watch: {
    $route: {
      handler(val) {
        // console.log(val)
        const route = this.tabbar.find((item) => item.path == val.path) || {};
        this.changeTab(route.value || -1);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["changeTab"]),
    async ask(path) {
      let userDetail = getLocalStorage("userInfo"),
        userDetailObj = JSON.parse(userDetail);
      if (userDetailObj && userDetailObj.user_id) {
        let userParams = {
          id: userDetailObj.user_id,
        };
        let userDetailQuery = await getUserDeatil(userParams),
          isCloseSpeak = userDetailQuery.data.isCloseSpeak;

        if (isCloseSpeak == 1) {
          this.$message.error("您已被禁言");
          return;
        }
      }

      if (this.$route.path !== path) {
        // this.current = -1;
        this.$router.push(path);
        this.changeTab(-1);
      }
    },
    onItemClick(item) {
      if (item.path !== this.$route.path) {
        // this.current = item.value;
        this.$router.push(item.path);
        this.changeTab(item.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tabbar {
  display: flex;
  align-items: center;
  height: 48px;
  font-size: 16px;
  color: #ffffff;
  .left__bg {
    height: 48px;
    background: #5cbd31;
    flex: 1;
  }
  .right__bg {
    flex: 1;
    height: 48px;
    background: #ff9d29;
  }
  .main {
    width: 1200px;
    min-width: 1100px;
    height: 48px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .left__tabbar__ul {
      height: 48px;
      display: flex;
      align-items: center;
      background: #5cbd31;
      li {
        margin: 0 30px;
        padding: 16px 42px;
        cursor: pointer;
        &.current {
          background: #50aa28;
          box-shadow: 0 -4px 0 0 #ff9d29 inset;
        }
      }
    }
    .icon {
      width: 46px;
      height: 48px;
      img {
        width: 46px;
        height: 48px;
      }
    }
    .right__content {
      flex: 1;
      height: 48px;
      display: flex;
      align-items: center;
      background: #ff9d29;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
      }
      .txt {
        margin-left: 8px;
      }
    }
  }
}
</style>
