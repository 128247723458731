<template>
  <div class="aside">
    <div class="box1">
      <div class="mian">
        <el-divider content-position="center">办理统计</el-divider>
        <el-row :gutter="18">
          <el-col :span="12">
            <div>帖子<b>{{replyCounts.total}}</b>条</div>
          </el-col>
          <el-col :span="12">
            <div>会员<b>{{replyCounts.userCount}}</b>条</div>
          </el-col>
          <el-col :span="12">
            <div>今日<b>{{replyCounts.todayCount}}</b>条</div>
          </el-col>
          <el-col :span="12">
            <div>昨日<b>{{replyCounts.yesterdayCount}}</b>条</div>
          </el-col>
        </el-row>
      </div>
      <el-button class="el__btn" @click="onAsk">我要问政</el-button>
    </div>
    <div class="box2">
      <el-carousel height="100px" indicator-position="outside" arrow="never">
        <el-carousel-item>
          <el-image :src="require('images/index/swiper1.png')"></el-image>
        </el-carousel-item>
      </el-carousel>
      <!-- <div class="qr">
        <el-image
          src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
        ></el-image>
        <div class="rt">
          <div class="title">衢网信用</div>
          <p>网信办开发的用于网络信用评价管理的政策宣传和服务类应用</p>
        </div>
      </div> -->
      <!-- <el-image
        class="jubao__img"
        :src="require('images/index/jbzx.png')"
      ></el-image> -->
    </div>
    <div class="box3">
      <div class="head">
        <span>排行榜</span>
        <div class="rt">
          <span
            v-for="(item, index) in tabs"
            :key="index"
            :class="{ current: item.value == current }"
            @click="onTabClick(item)"
            >{{ item.key }}</span
          >
        </div>
      </div>
      <table v-if="current === 1">
        <tr>
          <th>排名</th>
          <th colspan="2">单位名称</th>
          <th>满意数</th>
          <th>满意率</th>
        </tr>
        <tr v-for="(item, index) in pleaseList" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td colspan="2">{{ item.deptName }}</td>
          <td>{{ item.pleaseCount }}</td>
          <td>{{ item.pleaseRate }}%</td>
        </tr>
      </table>
      <table v-else>
        <tr>
          <th>排名</th>
          <th colspan="2">单位名称</th>
          <th>答复数</th>
          <th>答复率</th>
        </tr>
        <tr v-for="(item, index) in replyList" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td colspan="2">{{ item.deptName }}</td>
          <td>{{ item.replayCount }}</td>
          <td>{{ item.replayRate }}%</td>
        </tr>
      </table>
    </div>
    <!-- 舆情通报删除，暂时不要了，没有数据 -->
    <!-- <div class="box4">
      <div class="head">舆情通报</div>
      <ol>
        <li v-for="(item) in sentimentList" :key="item.id" @click="sentClick(item)">
          {{item.title}}
        </li>
      </ol>
    </div> -->
  </div>
</template>

<script>
import { getDeptPleaseList, getDeptReplyList,getReplyCount } from "@/api/wzaskscensus";
import { getSentiment } from "@/api/wzasksevent";
import { getUserDeatil } from "@/api/outh";
import { getLocalStorage } from "@/utils/tools.js";
export default {
  data() {
    return {
      // rankLoading:false,
      current: 1,
      tabs: [
        {
          key: "满意度",
          value: 1,
        },
        {
          key: "答复率",
          value: 2,
        },
      ],
      replyCounts:{},
      pleaseList: [],
      replyList: [],
      sentimentList:[]
    };
  },
  watch: {
    current: {
      handler(val) {
        // this.rankLoading = true;
        if (val === 1) {
          this.getDeptPleaseList();
        } else {
          this.getDeptReplyList();
        }
      },
      immediate: true,
    },
  },
  mounted(){
    this.getReplyCount()
    this.getSentiment()
  },
  methods: {
    sentClick(item){
      if(item.imgUrl) {
        window.open(item.imgUrl)
      } else {
        this.$router.push('/web/detail/'+item.id)
      }
    },
    async getSentiment(){
      const res = await getSentiment(1,10)
      // console.log(res)
      this.sentimentList = res.data.records
    },
    async getReplyCount(){
      const res = await getReplyCount();
      this.replyCounts = res.data;
    },
    async onAsk() {

      let userDetail = getLocalStorage("userInfo"),
        userDetailObj = JSON.parse(userDetail);
      if (userDetailObj && userDetailObj.user_id) {
        let userParams = {
          id: userDetailObj.user_id,
        };
        let userDetailQuery = await getUserDeatil(userParams),
          isCloseSpeak = userDetailQuery.data.isCloseSpeak;

        if (isCloseSpeak == 1) {
          this.$message.error("您已被禁言");
          return;
        }
      }

      this.$router.push("/web/ask");
    },
    async getDeptPleaseList() {
      const res = await getDeptPleaseList(8);
      // console.log(res)
      // this.rankLoading = false;
      this.pleaseList = res.data;
    },
    async getDeptReplyList() {
      const res = await getDeptReplyList(8);
      // console.log(res)
      // this.rankLoading = false;
      this.replyList = res.data;
    },
    onTabClick(item) {
      this.current = item.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.aside {
  .box1 {
    background: #ffffff;
    padding: 20px;
    .mian {
      background: #f9f9fa;
      padding: 16px 10px;
      .el-divider {
        margin: 12px 0;
      }
      .el-divider__text {
        background-color: #f9f9fa;
        color: #808a9c;
      }
      .el-col {
        font-size: 15px;
        color: #8590a6;
        padding: 10px 0;
        b {
          width: 50px;
          font-size: 15px;
          font-weight: 700;
          color: #57606b;
          display: inline-block;
          padding-left: 5px;
        }
      }
    }
    .el__btn {
      width: 100%;
      border: 1px solid #5cbd31;
      font-size: 15px;
      color: #5cbd31;
      margin-top: 18px;
    }
  }
  .box2 {
    .el-carousel {
      margin-top: 14px;
    }
    .qr {
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 2px 0px 0px #5cbd31 inset;
      padding: 10px;
      display: flex;
      align-items: center;
      .el-image {
        width: 76px;
        height: 79px;
      }
      .rt {
        flex: 1;
        padding-left: 12px;
        display: flex;
        flex-direction: column;
        .title {
          font-size: 18px;
          font-weight: 700;
          color: #333333;
        }
        p {
          font-size: 12px;
          color: #999999;
          line-height: 20px;
          margin-top: 8px;
        }
      }
    }
    .jubao__img {
      width: 300px;
      height: 82px;
      margin-top: 14px;
    }
  }
  .box3 {
    background: #ffffff;
    margin-top: 14px;
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 10px;
      box-shadow: 0px -1px 0px 0px #eeeeee inset;
      span {
        font-size: 16px;
        color: #808a9c;
      }
      .rt {
        display: flex;
        span {
          cursor: pointer;
          font-size: 14px;
          color: #808a9c;
          margin-left: 14px;
          &.current {
            font-weight: 700;
            color: #666a73;
          }
        }
      }
    }
    table {
      padding: 12px;
      font-size: 14px;
      color: #8590a6;
      line-height: 24px;
      text-align: center;
      width: 100%;
      table-layout: fixed;
      th,
      td {
        padding: 5px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .box4 {
    background: #ffffff;
    margin-top: 16px;
    .head {
      padding: 12px;
      box-shadow: 0px -1px 0px 0px #eeeeee inset;
      font-size: 16px;
      color: #808a9c;
    }
    ol {
      padding: 12px;
      li {
        font-size: 14px;
        color: #8590a6;
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        cursor: pointer;
        &::before {
          content: "";
          display: inline-block;
          width: 5px;
          height: 5px;
          background: #c7d0d9;
          margin: {
            right: 5px;
          }
        }
      }
    }
  }
}
</style>