import request from '../utils/request';
import _request from '../utils/_request';

// 满意度列表
export const getDeptPleaseList = (size) => _request({
  url: '/api/blade-asks/wzaskscensus/dept-please-list',
  method: 'get',
  showLoading: true,
  params: {
    size
  }
});
// 答复率列表
export const getDeptReplyList = (size) => _request({
  url: '/api/blade-asks/wzaskscensus/dept-reply-list',
  method: 'get',
  showLoading: true,
  params: {
    size
  }
});
// 满意度分页
export const getDeptPleasePage = (current,size) => request({
  url: '/api/blade-asks/wzaskscensus/dept-please-page',
  method: 'get',
  showLoading: true,
  params: {
    current,
    size
  }
});
// 答复率列表
export const getDeptReplyPage = (current,size) => request({
  url: '/api/blade-asks/wzaskscensus/dept-reply-page',
  method: 'get',
  showLoading: true,
  params: {
    current,
    size
  }
});
// 办理统计
export const getReplyCount = (startTime,endTime) => request({
  url: '/api/blade-asks/wzaskscensus/reply-count',
  method: 'get',
  params: {
    startTime,
    endTime,
    targetCategory: 101
  }
});