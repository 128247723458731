import axios from 'axios';
import {
  Message,
  Loading
} from 'element-ui';
import {
  tenantId,
  Authorization
} from './const';
import store from "@/store"
import router from '@/router';
const service = axios.create({
  // baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000,
  validateStatus: status => status >= 200 && status < 500,
  // withCredentials: true
});
// let loadingInstance
// request拦截器  
service.interceptors.request.use(
  config => {
    // console.log(store)
    const userInfo = store.state.userInfo
    config.headers['Authorization'] = 'Basic ' + Authorization;
    config.headers['tenantId'] = tenantId;

    if (userInfo) {
      config.headers['Blade-Auth'] = 'bearer ' + userInfo.access_token
    }
    if (config.showLoading) {
      // loadingInstance = Loading.service({
      //   lock: true,
      //   text: '数据加载中...',
      //   spinner: 'el-icon-loading',
      // });
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  res => {
    // console.log("请求返回", res);
    const status = res.data.code || res.status;
    const message = res.data.msg || res.statusText;
    // loadingInstance && loadingInstance.close()
    if (status === 401) {
      store.dispatch('logout')
      // router.push('/web/login')
    }  
    if (status !== 200&&status !== 401) {
      Message({
        message: message,
        type: 'error'
      })
      return Promise.reject(message);
    }
    return res.data;
  },
  error => {
    return Promise.reject(error);
  }
);

export default service;