import request from '../utils/request';
import md5 from 'js-md5'; 
import { rsaEncrypt } from "@/utils/encryp"
import {
  tenantId
} from "@/utils/const";

// 图片验证码
export const getCaptcha = () => request({
  url: '/api/blade-auth/oauth/captcha',
  method: 'get',
});

// 获取短信验证码
export const getSms = (phone,smsKey,smsCode) => request({
  url: '/api/blade-auth/oauth/sms',
  method: 'post',
  headers: {
    'Captcha-Key': smsKey,
    'Captcha-Code': smsCode,
  },
  params: {
    phone
  },
});

// 验证码登录
export const loginBySms = (smsKey, smsCode, phone) => request({
  url: '/api/blade-auth/oauth/token',
  method: 'post',
  headers: {
    'User-Type': 'front',
    'Sms-Captcha-Id': smsKey,
    'Sms-Captcha-Code': smsCode,
  },
  params: {
    tenantId,
    grant_type: "sms",
    phone
  }
});

// 账号密码登录
export const loginByUsername = (captchaKey, captchaCode, username, password) => request({
  url: '/api/blade-auth/oauth/token',
  method: 'post',
  headers: {
    'User-Type': 'front',
    'Captcha-Key': captchaKey,
    'Captcha-Code': captchaCode,
  },
  params: {
    tenantId,
    grant_type: "captcha",
    username,
    password:rsaEncrypt(password),
  }
});

// 注册 
export const reigster = (captchaKey, captchaCode, smsKey, smsCode, name, phone, password, password1) => request({
  url: '/api/blade-user/front/register',
  method: 'post',
  headers: {
    'Captcha-Key': captchaKey,
    'Captcha-Code': captchaCode,
    'Sms-Captcha-Id': smsKey,
    'Sms-Captcha-Code': smsCode,
  },
  data: {
    tenantId,
    name,
    phone,
    // password:md5(password),
    // password1:md5(password1),
    password:rsaEncrypt(password),
    password1:rsaEncrypt(password1),
  }
});

// 重置密码
export const forgetReset = (smsKey, smsCode, phone, password, password1) => request({
  url: '/api/blade-user/front/forget-reset',
  method: 'post',
  headers: {
    'Sms-Captcha-Id': smsKey,
    'Sms-Captcha-Code': smsCode,
  },
  data: {
    tenantId,
    phone,
    // password:md5(password),
    // password1:md5(password1)
    password:rsaEncrypt(password),
    password1:rsaEncrypt(password1),
  }
});


// 退出登录
export const logout = () => request({
  url: '/api/blade-auth/oauth/logout',
  method: 'get',
});

// 修改手机号
export const updatePhone = (smsKey, smsCode, phone) => request({
  url: '/api/blade-user/front/update-phone',
  method: 'post',
  headers: {
    'Sms-Captcha-Id': smsKey,
    'Sms-Captcha-Code': smsCode,
  },
  data: {
    phone,
  }
});

// 修改密码
export const updatePassword = (oldPassword, newPassword, newPassword1) => request({
  url: '/api/blade-user/front/update-password',
  method: 'post',
  data: {
    oldPassword:rsaEncrypt(oldPassword),
    newPassword:rsaEncrypt(newPassword),
    newPassword1:rsaEncrypt(newPassword1),
    // newPassword:md5(newPassword),
    // newPassword1:md5(newPassword1),
  }
});

// 修改基本信息
export const updateInfo= ({name,avatar,realName,idCard}) => request({
  url: '/api/blade-user/front/update-info',
  method: 'post',
  data: {
    name,
    avatar,
    realName,
    idCard
  }
});

// 修改基本信息
export const reviewInfo= ({nickName,avatar,realName,idCard,reviewType}) => request({
  url: '/api/blade-general/review/front',
  method: 'post',
  data: {
    nickName,
    avatar,
    realName,
    idCard,
    reviewType // 1：头像，2：昵称，3：身份信息（姓名、身份证号）
  }
});

// 刷新token
export const refreshToken = (refresh_token) => request({
  url: '/api/blade-auth/oauth/token',
  method: 'post',
  params: {
    tenantId,
    refresh_token,
    grant_type: "refresh_token",
    scope: "all",
  }
});

// 用户信息详情
export const getUserDeatil = (params) => request({
  url: '/api/blade-user/info',
  method: 'get',
  params
});

