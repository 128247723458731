<template>
  <div class="header">
    <div class="search">
      <el-input
        size="small"
        class="el__input"
        placeholder="输入关键字"
        clearable
        v-model="searchValue"
        @clear="clear"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="search(searchValue)"
        ></el-button>
      </el-input>
      <div class="action__text">
        <template v-if="!userInfo||!(userInfo.nick_name || userInfo.account)">
          <span @click="login">登录</span>
          <span>|</span>
          <span @click="reigster">注册</span>
        </template>
        <template v-else>
          <span @click="onMyclick">{{
            userInfo.nick_name || userInfo.account
          }}</span>
          <span>|</span>
          <span @click="onLogout">退出</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from "@/api/outh";
import { mapState, mapActions } from "vuex";
export default {
  name: "Header",
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      keyword: (state) => state.keyword,
    }),
  },
  data() {
    return {
      searchValue: "",
    };
  },
  mounted() {
    console.log(this.userInfo);
  },
  methods: {
    ...mapActions(["logout", "changeKeyword"]),
    onMyclick() {
      if (this.$route.path !== "/web/personalCenter") {
        this.$router.push("/web/personalCenter");
      }
    },
    login() {
      if (this.$route.path !== "/web/login") {
        this.$router.push("/web/login");
      }
    },
    reigster() {
      if (this.$route.path !== "/web/reigster") {
        this.$router.push("/web/reigster");
      }
    },
    async onLogout() {
      const res = await logout();
      res && this.$message.success("退出成功");
      this.logout();
    },
    clear() {
      this.changeKeyword("");
      this.$router.go(-1);
    },
    search(val) {
      if (!val) return;
      this.changeKeyword(val);
      if (this.$route.path !== "/web/search") {
        this.$router.push({
          path: "/web/search",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  width: 100%;
  height: 250px;
  background: url("~images/common/banner.png") no-repeat left top;
  background-size: cover;
  background-position: center top; // 设置背景图像居中显示
  .search {
    position: absolute;
    top: 66px;
    right: 20%;
    display: flex;
    align-items: center;
    .el__input {
      width: 300px;
    }
    .action__text {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #333333;
      margin-left: 20px;
      cursor: pointer;
      span {
        padding: 0 5px;
      }
    }
  }
}
</style>
