export const tenantId = '023934'; //租户Id
export const appKey = "9ha4JHX2dE" // 应用key后台生成
export const Authorization = "ZnJvbnQ6ZnJvbnRfc2VjcmV0" // 应用key后台生成
export const rsaPublicKey = "-----BEGIN PUBLIC KEY-----\n" +
"MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4k0M+SI0WaJZSKWP8jwODp+Y6g7o5fHJ\n" +
"b7bs6f0AvN/lBqLuet+sskMjWZZ6FaJcjiRVKhoIHr5w6K7bEin4N0dDcHHgYvNWGjIdHtrZVPFD\n" +
"D6J00sForiK5dU0IKQWsDRkwxYDQyTJy0MG/83oPsTEDpLPCrTSzfmtoL22i2LbvxNaJsQJ+ycTH\n" +
"2EaFUYoQMTnU1dEqGvFICAZKiHpSH46Py0WciZseKkiuNLXlSFDeKuKq1p1VG5xhk1YEa8tL4V2J\n" +
"t+5Aoy9lLkE2tZpgQEOeRSjZ9hbtduBpRuLqVAtPK01P2ZDr7XWpjBggZIkeXQiwvp/JRQHmOd3Z\n" +
"dcy3QQIDAQAB\n" +
"-----END PUBLIC KEY-----" // 公钥
export const STATUS = {
  STATUS_START: 0, //提问初始状态 
  STATUS_PASS: 1, //审核通过
  STATUS_FAIL_PASS: 5, //审核不通过
  STATUS_TRANSFER: 6, //转办
  STATUS_ACCEPT: 10, //受理
  STATUS_BACK: 20, //反馈或已答复
  STATUS_APPRAISE: 30 //评价
}