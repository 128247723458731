<template>
  <div class="footer">
    <p>主办：衢州市新闻传媒中心 承办：衢州新闻网</p>
    <p>
      浙ICP备07022324号 Copyright © 2007-2016 Qz828.com Inc. All Rights Reserved.
    </p>
  </div>
</template>

<script>
export default {
  name:'Footer'
};
</script>

<style lang="scss" scoped>
.footer {
  background: #edeff2;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    font-size: 13px;
    color: #999999;
    line-height: 26px;
  }
}
</style>