import request from '../utils/request';

//获取问政列表
export const getAskList = (current, size,params,showLoading=true) => request({
  url: '/api/blade-asks/wzasksevent/front/pcpage',
  // url: '/api/blade-asks/wzasksevent/front/page',
  method: 'get',
  showLoading,
  params: {
    ...params,
    current, 
    size,
    typeId:1,
    targetCategory: 101
  },
});

//获取问政详情
export const getAskDetail = (id) => request({
  url: `/api/blade-asks/wzasksevent/front/${id}`,
  showLoading:true,
  method: 'get',
});

// 问政新增
export const addAsk= ({title,content,deptId,imgUrl,videoUrl,isClue}) => request({
  url: '/api/blade-asks/wzasksevent/front',
  method: 'post',
  showLoading:true,
  data: {
    clientId:1,
    title,content,deptId,imgUrl,videoUrl,isClue,
    targetCategory: 101
  }
});

// 问政修改
export const updateAsk= (id,{title,content,deptId,imgUrl,videoUrl,isClue}) => request({
  url: `/api/blade-asks/wzasksevent/front/${id}`,
  method: 'PUT',
  showLoading:true,
  data: {
    id,
    title,
    content,
    deptId,
    imgUrl,
    videoUrl,
    isClue
  }
});

// 问政删除
export const delAsk= (id) => request({
  url: `/api/blade-asks/wzasksevent/front/${id}`,
  method: 'DELETE',
});

// 我回复的提问列表
export const getMyComments = (current, size) => request({
  url: '/api/blade-asks/wzasksevent/asks/mycomments',
  method: 'GET',
  showLoading:true,
  params:{
    current, 
    size,
  }
});

// 我点赞的提问列表
export const getMyLikes = (current, size) => request({
  url: '/api/blade-asks/wzasksevent/asks/mylikes',
  method: 'GET',
  showLoading:true,
  params:{
    current, 
    size,
  }
});
// 舆情通报
export const getSentiment = (current, size) => request({
  url: '/api/blade-asks/wzasksevent/front/sentiment/page',
  method: 'GET',
  params: {
    current, 
    size
  }
});


export const addFeedbackAsk= (data) => request({
  url: '/api/blade-asks/wzasksfeedback/front',
  method: 'post',
  showLoading:true,
  data,
});
