/**
 * 工具类
 */
import CryptoJS from "crypto-js";
let keyStr = "dadojd6g89asvav45aaajfvew7v12dew";
import JSEncrypt from 'jsencrypt';
import {rsaPublicKey} from './const';

//加密
// export function encrypt(word, keyStr){
export function encrypt(word) {
  keyStr = keyStr ? keyStr : "abcdefgabcdefg12";
  var key = CryptoJS.enc.Utf8.parse(keyStr); //Latin1 w8m31+Yy/Nw6thPsMpO5fg==
  var srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}

//解密
// export function decrypt(word, keyStr){
export function decrypt(word) {
  keyStr = keyStr ? keyStr : "abcdefgabcdefg12";
  var key = CryptoJS.enc.Utf8.parse(keyStr); //Latin1 w8m31+Yy/Nw6thPsMpO5fg==
  var decrypt = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}

export function rsaEncrypt(data){
  var encryptor = new JSEncrypt();
  encryptor.setPublicKey(rsaPublicKey);
  return encryptor.encrypt(data);
}
