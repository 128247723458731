export default [{
  path: 'index',
  name: '首页',
  component: () => import( '@/page/web/index'),
  meta:{
    keepAlive: false,
    hanAside:true
  },
},{
  path: 'login',
  name: '登录',
  component: () => import( '@/page/web/login'),
  meta:{
    hanAside:false
  }
},{
  path: 'reigster',
  name: '注册',
  component: () => import( '@/page/web/reigster'),
  meta:{
    hanAside:false
  },
},{
  path: 'forget',
  name: '忘记密码',
  component: () => import( '@/page/web/forget'),
  meta:{
    hanAside:false
  },
},{
  path: 'personalCenter',
  name: '个人中心',
  component: () => import( '@/page/web/personalCenter'),
  meta:{
    hanAside:false,
    keepAlive:false
  },
},{
  path: 'release',
  name: '权威发布',
  component: () => import( '@/page/web/release'),
  meta:{
    hanAside:true
  },
},{
  path: 'media',
  name: '媒体问政',
  component: () => import( '@/page/web/media'),
  meta:{
    hanAside:true
  },
},{
  path: 'newDetail/:id',
  name: '新闻详情',
  component: () => import( '@/page/web/newDetail'),
  meta:{
    hanAside:false
  },
},{
  path: 'online/:page/:type',
  name: '在线问政',
  component: () => import( '@/page/web/online'),
  meta:{
    hanAside:true,
    keepAlive:false
  },
},{
  path: 'suggest',
  name: '民间智库',
  component: () => import( '@/page/web/suggest'),
  meta:{
    hanAside:true,
    keepAlive:false
  },
},{
  path: 'policy',
  name: '最新政策',
  component: () => import( '@/page/web/policy'),
  meta:{
    hanAside:true,
    keepAlive:false
  },
},{
  path: 'rank',
  name: '排行榜',
  component: () => import( '@/page/web/rank'),
  meta:{
    hanAside:true
  },
},{
  path: 'ask',
  name: '我要问政',
  component: () => import( '@/page/web/ask'),
  meta:{
    hanAside:false
  },
},{
  path: 'pubWarehouse',
  name: '民间智库',
  component: () => import( '@/page/web/pubWarehouse'),
  meta:{
    hanAside:false
  },
},{
  path: 'search',
  name: '搜索',
  component: () => import( '@/page/web/search'),
  meta:{
    hanAside:true
  },
},{
  path: 'detail',
  name: '问政详情',
  component: () => import( '@/page/web/detail'),
  meta:{
    hanAside:false
  },
},{
  path: 'zkdetail/:id',
  name: '智库详情',
  component: () => import( '@/page/web/zkdetail'),
  meta:{
    hanAside:false
  },
},{
  path: 'zcdetail/:id',
  name: '政策详情',
  component: () => import( '@/page/web/zcdetail'),
  meta:{
    hanAside:false
  },
},{
  path: 'depts',
  name: '部门在线',
  component: () => import( '@/page/web/depts'),
  meta:{
    hanAside:true
  },
},{
  path: 'deptlist/:id/:name/:page/:type',
  name: '部门在线列表',
  component: () => import( '@/page/web/deptlist'),
  meta:{
    hanAside:true
  },
},]