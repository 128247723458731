<template>
  <div class="wrap">
    <!-- header -->
    <Head></Head>
    <!-- tabbar  -->
    <Tabbar></Tabbar>
    <!-- router-view -->
    <div class="container">
      <div :class="{'content':$route.meta.hanAside}">
        <div :class="{'main':$route.meta.hanAside}">
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
        </div>
        <div v-if="$route.meta.hanAside" class="aside">
          <Aside></Aside>
        </div>
      </div>
    </div>
    <!-- footer -->
    <Footer></Footer>
  </div>
</template>

<script>
import Head from "@/components/Head";
import Tabbar from "@/components/Tabbar";
import Footer from "@/components/Footer";
import Aside from "@/components/Aside";
export default {
  name: "home",
  components: {
    Head,
    Tabbar,
    Footer,
    Aside,
  },
  data() {
    return {};
  },
  mounted() {
    // console.log(this.$route);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  width: 1126px;
  margin: 0 auto;
  .content {
    margin-top: 22px;
    display: flex;
    justify-content: space-between;
    .main {
      width: 810px;
    }
    .aside {
      width: 300px;
    }
  }
}
</style>