import Vue from "vue"
import Vuex from "vuex"
import router from '../router';
import { setLocalStorage, getLocalStorage,removeLocalStorage } from "@/utils/tools.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentTab: 1,
    userInfo: new Function("return " + getLocalStorage("userInfo"))()||{},
    keyword:'' // 搜索关键词
  },
  actions:{
    changeTab({ commit }, value){
      commit('changeTab', value);
    },
    setUserInfo({ commit }, value){
      commit('setUserInfo', value);
    },
    logout({ commit }){
      commit('logout');
    },
    changeKeyword({ commit },value){
      commit('changeKeyword',value);
    },
  },
  mutations: {
    changeTab(state, value) {
      state.currentTab = value;
    },
    setUserInfo(state, value) {
      state.userInfo = value;
      setLocalStorage('userInfo', JSON.stringify(value))
    },
    logout(state) {
      router.push('/web/login')
      state.userInfo = null;
      removeLocalStorage('userInfo')
    },
    changeKeyword(state,value) {
      state.keyword = value;
    },
  }
})