import Vue from 'vue';
import Router from 'vue-router';
import layout from '@/page/layout'
import web from './web'
Vue.use(Router);
export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/web'
    },
    {
      path: "/404",
      component: () => import( '@/page/404'),
    },
    {
      path: '/web',
      component: layout,
      redirect: '/web/index',
      children : [
        ...web
      ]
    },
    {
      path: '*',
      redirect:'/'
    }
  ]
  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition
  //   } else {
  //     return {
  //       x: 0,
  //       y: 0
  //     }
  //   }
  // }
})