import Vue from 'vue'
import App from './App.vue'
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'style/reset.css'
import store from './store/index';
import { encrypt, decrypt } from '@/utils/encryp.js';

Vue.use(ElementUI, {
  size: '',
  zIndex: 2000
});
Vue.prototype.encrypt = encrypt
Vue.prototype.decrypt = decrypt
Vue.config.productionTip = false

// router.beforeEach((to, from, next) => {
//   console.log(to, from)   
//   next()
// })

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')